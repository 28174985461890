<template>
  <v-container>
    <v-treeview
      v-if="roles.length !== 0"
      :items="getTranslatedRoles"
      :open="open"
      item-key="SystemRoleID"
      open-all
    >
      <template v-if="displayFormat === 'checkbox'" v-slot:label="{ item }">
        <div @contextmenu="setContextMenu(item, $event)">
          {{ item.name }}<br />{{ item.description }}
        </div>
      </template>
      <template v-else v-slot:label="{ item }">
        <div v-if="item.divider">
          <v-divider class="ma-0 pa-0"></v-divider>
        </div>
        <div v-else>
          {{ item.name }}
        </div>
      </template>
      <template v-slot:append="{ item }">
        <template v-if="!item.divider">
          <mex-btn
            v-if="displayFormat === 'edit' && item.default === 0 && writeRoles"
            icon="mdi-delete-outline"
            iconOnly
            tooltip="delete role"
            @click="deleteRole(item)"
          />
          <mex-btn
            v-if="displayFormat === 'edit'"
            icon="mdi-circle-edit-outline"
            iconOnly
            tooltip="edit role"
            @click="goToEditRole(item.SystemRoleID)"
          />
          <mex-btn
            v-if="displayFormat === 'edit' && writeRoles"
            icon="mdi-account-multiple-plus"
            iconOnly
            tooltip="add new child role"
            @click="goToAddNewRole(item.SystemRoleID)"
          />
          <mex-btn
            v-if="displayFormat === 'edit' && writeRoles"
            icon="mdi-form-select"
            iconOnly
            tooltip="add new role by template"
            @click="goToAddNewRoleByTemplate(item.SystemRoleID)"
          />
          <mex-btn
            v-if="displayFormat === 'edit' && readUsers"
            icon="mdi-account-group"
            iconOnly
            tooltip="See all connected users"
            @click="gotToConnectedUsers(item.SystemRoleID)"
          />
        </template>
      </template>
      <template v-slot:prepend="{ item }">
        <v-checkbox
          v-if="displayFormat === 'checkbox'"
          v-model="item.checked"
          :disabled="!editMode || item.disabled"
          @change="checkBoxUpdated($event, item)"
        />
      </template>
    </v-treeview>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">Are you sure you want to delete this role?</v-card-title>
        <v-card-text v-if="toDeleteRole && toDeleteRole.children.length !== 0">
          If this role is deleted, the children roles will be attached to the next higher parent
          role!
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <mex-btn content="Cancel" text @click="closeDelete"></mex-btn>
          <mex-btn content="Delete" text @click="deleteRoleConfirm"></mex-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-menu v-model="showContextMenu" :position-x="cmPosX" :position-y="cmPosY" absolute offset-y>
      <v-list>
        <v-list-item v-for="(cmItem, index) in cmItems" :key="index" @click="cmItem.function">
          <v-list-item-icon>
            <v-icon>{{ cmItem.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ cmItem.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import SystemRolesService from "../../services/systemRoles.service";

export default {
  name: "RoleTreeview.vue",
  props: {
    roles: {
      type: Array,
      default: () => {
        return [];
      },
      description: "roles for the treeview"
    },
    selectedRoles: {
      type: Array,
      default: () => {
        return [];
      },
      description: "ids of the roles that are selected"
    },
    displayFormat: {
      type: String,
      default: () => {
        return "checkbox";
      },
      description: "decides which format is used"
    },
    editMode: {
      type: Boolean,
      default: () => {
        return true;
      },
      description: "decides if the checkboxes are editable"
    },
    dividers: {
      type: Boolean,
      default: () => {
        return true;
      }
    }
  },
  computed: {
    ...mapGetters("sysAuth", ["getUserPermissions"]),
    getSelectedRoles() {
      return this.selectedRoles;
    },
    open() {
      const open = [];
      this.checkIfOpen(open, this.roles);
      return open;
    },
    writeRoles() {
      return this.getUserPermissions.includes("write_System_User Role Administration_Role");
    },
    readUsers() {
      return this.getUserPermissions.includes("read_System_User Role Administration_User");
    },
    getTranslatedRoles() {
      if (this.dividers && this.displayFormat === "edit") {
        const dividedRoles = [];
        this.addDividersToRoles(this.roles, dividedRoles);
        return dividedRoles;
      }
      return this.roles;
    }
  },
  data() {
    return {
      dialogDelete: false,
      toDeleteRole: null,
      cmItems: [
        {
          title: "select all children",
          icon: "mdi-checkbox-multiple-marked-outline",
          function: this.selectAllChildren
        },
        {
          title: "deselect all children",
          icon: "mdi-checkbox-multiple-blank-outline",
          function: this.deselectAllChildren
        }
      ],
      showContextMenu: false,
      cmPosX: 0,
      cmPosY: 0,
      selectedItem: {},
      newlySelectedRoles: [],
      newlyDeselectedRoles: []
    };
  },
  methods: {
    addDividersToRoles(roles, dividedRoles) {
      for (let i = 0; i < roles.length; i++) {
        const role = { ...roles[i] };
        delete role.children;
        role.divider = false;
        dividedRoles.push(role);
        dividedRoles[dividedRoles.length - 1].children = [];
        if (roles[i].children.length !== 0) {
          this.addDividersToRoles(
            roles[i].children,
            dividedRoles[dividedRoles.length - 1].children
          );
        }
        if (i < roles.length - 1) {
          dividedRoles.push({ divider: true, children: [] });
        }
      }
    },
    checkIfOpen(open, roles) {
      let openParents = false;
      for (let i = 0; i < roles.length; i++) {
        if (roles[i].checked) {
          openParents = true;
        }
        if (roles[i].children.length !== 0) {
          if (this.checkIfOpen(open, roles[i].children)) {
            open.push(roles[i].SystemRoleID);
            openParents = true;
          }
        }
      }
      return openParents;
    },
    gotToConnectedUsers(systemRoleID) {
      this.$router.push({ name: "UsersOverview", params: { roleID: systemRoleID } });
    },
    goToEditRole(systemRoleID) {
      this.$router.push({ name: "RoleView", params: { id: systemRoleID } });
    },
    goToAddNewRole(systemRoleID) {
      this.$router.push({ name: "AddRole", params: { parentRoleID: systemRoleID } });
    },
    goToAddNewRoleByTemplate(systemRoleID) {
      this.$router.push({ name: "AddRole", params: { templateRoleID: systemRoleID } });
    },
    deleteRole(item) {
      this.toDeleteRole = item;
      this.dialogDelete = true;
    },
    deleteRoleConfirm() {
      SystemRolesService.deleteRole(this.toDeleteRole.SystemRoleID)
        .then(() => {
          this.$toast.success("Deleted successfully");
          this.$router.push({ name: "ParamsReload", params: { name: "RolesOverview" } });
        })
        .catch(err => {
          this.$toast.error(`Error while deleting: ${err.response.data.message}`);
          this.closeDelete();
        });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.toDeleteRole = null;
      });
    },
    setContextMenu(item, e) {
      e.preventDefault();
      this.showContextMenu = false;
      this.cmPosX = e.clientX;
      this.cmPosY = e.clientY;
      this.$nextTick(() => {
        this.showContextMenu = true;
      });
      this.selectedItem = item;
    },
    checkBoxUpdated(newValue, item) {
      if (newValue) {
        this.selectRole(item);
      } else {
        this.deselectRole(item);
      }
    },
    selectRole(item) {
      this.newlyDeselectedRoles = [];
      this.newlySelectedRoles = [];
      this.newlySelectedRoles.push(item.SystemRoleID);
      if (item.children.length !== 0) this.deselectAndDisableAllChildren(item.children);
      this.$emit(
        "roleSelectionChanged",
        this.getSelectedRoles
          .concat(this.newlySelectedRoles)
          .filter(permission => !this.newlyDeselectedRoles.includes(permission))
      );
    },
    deselectAndDisableAllChildren(items) {
      for (let i = 0; i < items.length; i++) {
        if (!items[i].divider) items[i].disabled = true;
        if (items[i].checked) {
          this.newlyDeselectedRoles.push(items[i].SystemRoleIDs);
          items[i].checked = false;
        }
        if (items[i].children && items[i].children.length !== 0)
          this.deselectAndDisableAllChildren(items[i].children);
      }
    },
    deselectRole(item) {
      if (item.children.length !== 0) this.enableAllChildren(item.children);
      this.$emit(
        "roleSelectionChanged",
        this.getSelectedRoles.filter(permission => permission !== item.SystemRoleID)
      );
    },
    enableAllChildren(items) {
      for (let i = 0; i < items.length; i++) {
        items[i].disabled = false;
        if (items[i].children.length !== 0) this.enableAllChildren(items[i].children);
      }
    },
    selectAllChildren() {
      if (
        !this.selectedItem.disabled &&
        !this.selectedItem.checked &&
        this.selectedItem.children.length !== 0
      ) {
        this.newlySelectedRoles = [];
        this.newlyDeselectedRoles = [];
        this.selectedItem.children.forEach(item => {
          if (!item.divider && !item.checked) {
            item.checked = true;
            this.newlySelectedRoles.push(item.SystemRoleID);
            if (item.children.length !== 0) this.deselectAndDisableAllChildren(item.children);
          }
        });
        this.$emit(
          "roleSelectionChanged",
          this.getSelectedRoles
            .concat(this.newlySelectedRoles)
            .filter(permission => !this.newlyDeselectedRoles.includes(permission))
        );
      }
    },
    deselectAllChildren() {
      if (
        !this.selectedItem.disabled &&
        !this.selectedItem.checked &&
        this.selectedItem.children.length !== 0
      ) {
        this.newlyDeselectedRoles = [];
        this.selectedItem.children.forEach(item => {
          if (item.checked) {
            item.checked = false;
            this.newlyDeselectedRoles.push(item.SystemRoleID);
            this.enableAllChildren(item.children);
          } else if (item.children.length !== 0) {
            this.deselectAndDisableAllChildren(item.children);
          }
        });
        this.$emit(
          "roleSelectionChanged",
          this.getSelectedRoles
            .concat(this.newlySelectedRoles)
            .filter(permission => !this.newlyDeselectedRoles.includes(permission))
        );
      }
    }
  }
};
</script>

<style scoped></style>
