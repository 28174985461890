<template>
  <v-container v-if="hasPermission" fluid>
    <mex-heading content="Roles Overview" />
    <v-row justify="center">
      <v-col cols="6">
        <mex-card icon="mdi-account-multiple">
          <v-row>
            <role-treeview :roles="roles" displayFormat="edit"></role-treeview>
          </v-row>
        </mex-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import RoleTreeview from '../../components/LicSrvComponents/RoleTreeview.vue';
import SystemRolesService from '../../services/systemRoles.service';
import requiredPermissions from '../../requiredPermissions';

export default {
  name: 'RolesOverview.vue',
  components: {
    RoleTreeview,
  },
  computed: {
    ...mapGetters('sysAuth', ['getUserPermissions']),
    readRoles() {
      return this.getUserPermissions.includes('read_System_User Role Administration_Role');
    },
    writeRoles() {
      return this.getUserPermissions.includes('write_System_User Role Administration_Role');
    },
  },
  data() {
    return {
      roles: [],
      hasPermission: false,
    };
  },
  methods: {
    fetchRoles() {
      SystemRolesService.getRoles().then((response) => {
        this.roles = response.data.roles;
      });
    },
  },
  created() {
    this.$userPermissions.fetchCurrentUserPermissions(requiredPermissions.roleOverview, this.$store)
      .then((hasPermission) => {
        if (hasPermission) {
          this.hasPermission = true;
          this.fetchRoles();
        } else {
          this.$router.push({ name: "NotFound" });
        }
      })
      .catch(() => {
        this.$router.push({ name: "NotFound" });
      })
  },
};
</script>

<style scoped></style>
