var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(_vm.roles.length !== 0)?_c('v-treeview',{attrs:{"items":_vm.getTranslatedRoles,"open":_vm.open,"item-key":"SystemRoleID","open-all":""},scopedSlots:_vm._u([(_vm.displayFormat === 'checkbox')?{key:"label",fn:function(ref){
var item = ref.item;
return [_c('div',{on:{"contextmenu":function($event){return _vm.setContextMenu(item, $event)}}},[_vm._v(" "+_vm._s(item.name)),_c('br'),_vm._v(_vm._s(item.description)+" ")])]}}:{key:"label",fn:function(ref){
var item = ref.item;
return [(item.divider)?_c('div',[_c('v-divider',{staticClass:"ma-0 pa-0"})],1):_c('div',[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"append",fn:function(ref){
var item = ref.item;
return [(!item.divider)?[(_vm.displayFormat === 'edit' && item.default === 0 && _vm.writeRoles)?_c('mex-btn',{attrs:{"icon":"mdi-delete-outline","iconOnly":"","tooltip":"delete role"},on:{"click":function($event){return _vm.deleteRole(item)}}}):_vm._e(),(_vm.displayFormat === 'edit')?_c('mex-btn',{attrs:{"icon":"mdi-circle-edit-outline","iconOnly":"","tooltip":"edit role"},on:{"click":function($event){return _vm.goToEditRole(item.SystemRoleID)}}}):_vm._e(),(_vm.displayFormat === 'edit' && _vm.writeRoles)?_c('mex-btn',{attrs:{"icon":"mdi-account-multiple-plus","iconOnly":"","tooltip":"add new child role"},on:{"click":function($event){return _vm.goToAddNewRole(item.SystemRoleID)}}}):_vm._e(),(_vm.displayFormat === 'edit' && _vm.writeRoles)?_c('mex-btn',{attrs:{"icon":"mdi-form-select","iconOnly":"","tooltip":"add new role by template"},on:{"click":function($event){return _vm.goToAddNewRoleByTemplate(item.SystemRoleID)}}}):_vm._e(),(_vm.displayFormat === 'edit' && _vm.readUsers)?_c('mex-btn',{attrs:{"icon":"mdi-account-group","iconOnly":"","tooltip":"See all connected users"},on:{"click":function($event){return _vm.gotToConnectedUsers(item.SystemRoleID)}}}):_vm._e()]:_vm._e()]}},{key:"prepend",fn:function(ref){
var item = ref.item;
return [(_vm.displayFormat === 'checkbox')?_c('v-checkbox',{attrs:{"disabled":!_vm.editMode || item.disabled},on:{"change":function($event){return _vm.checkBoxUpdated($event, item)}},model:{value:(item.checked),callback:function ($$v) {_vm.$set(item, "checked", $$v)},expression:"item.checked"}}):_vm._e()]}}],null,true)}):_vm._e(),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("Are you sure you want to delete this role?")]),(_vm.toDeleteRole && _vm.toDeleteRole.children.length !== 0)?_c('v-card-text',[_vm._v(" If this role is deleted, the children roles will be attached to the next higher parent role! ")]):_vm._e(),_c('v-card-actions',[_c('v-spacer'),_c('mex-btn',{attrs:{"content":"Cancel","text":""},on:{"click":_vm.closeDelete}}),_c('mex-btn',{attrs:{"content":"Delete","text":""},on:{"click":_vm.deleteRoleConfirm}}),_c('v-spacer')],1)],1)],1),_c('v-menu',{attrs:{"position-x":_vm.cmPosX,"position-y":_vm.cmPosY,"absolute":"","offset-y":""},model:{value:(_vm.showContextMenu),callback:function ($$v) {_vm.showContextMenu=$$v},expression:"showContextMenu"}},[_c('v-list',_vm._l((_vm.cmItems),function(cmItem,index){return _c('v-list-item',{key:index,on:{"click":cmItem.function}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(cmItem.icon))])],1),_c('v-list-item-title',[_vm._v(_vm._s(cmItem.title))])],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }